export default function Modal(props) {
  return (
    <div
      className="modal custom-modal fade online-course-modal"
      id={props.title + 'Modal'}
      tabIndex="-1"
      aria-labelledby={props.title + 'ModalLabel'}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title fs-5" id={props.title + 'ModalLabel'}>
              <a href={props.linkCourse}>
                <img className="img-fluid" src={props.image} alt="" />
              </a>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <ul>
              {props.list.map((item, i) => {
                return (
                  <li key={i}>
                    <a href={item.link}>{item.text}</a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
