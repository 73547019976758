export default function Contact() {
  return (
    <div className="animate__animated animate__fadeIn">
      <main className="content-page">
        <div className="container">
          <h2 className="title-page">Contact</h2>
          <div className="content contact shadow">
            <p>
              <img src="assets/svg/telegram.svg" alt="..." />
              <a href="https://t.me/rizallk87/">t.me/rizallk87</a>
            </p>
            <p>
              <img src="assets/svg/facebook.svg" alt="..." />
              <a href="https://www.facebook.com/rizall.kadamong.1/">
                facebook.com/rizall.kadamong.1
              </a>
            </p>
            <p>
              <img src="assets/svg/instagram.svg" alt="..." />
              <a href="https://www.instagram.com/rzll.kadamong21/">
                instagram.com/rzll.kadamong21
              </a>
            </p>
            <p>
              <img src="assets/svg/linkedin.svg" alt="..." />
              <a href="https://www.linkedin.com/in/rizall-kadamong/">
                linkedin.com/in/rizall-kadamong
              </a>
            </p>
            <p>
              <img src="assets/svg/github.svg" alt="..." />
              <a href="https://github.com/rizallk/">github.com/rizallk</a>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}
