import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Skills from './pages/Skills';
import Navbar from './components/Navbar';
import Achievements from './pages/Achievements';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import CrD from './components/CrD';
import CrM from './components/CrM';
import 'particles.js/particles';
import './App.scss';

const particlesJS = window.particlesJS;

function App() {
  particlesJS.load('particles-js', './assets/json/particlesjs-config.json');

  return (
    <>
      <div id="particles-js"></div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/achievements" element={<Achievements />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <CrD />
      <CrM />
    </>
  );
}

export default App;
