import Project from '../components/Project';

export default function Projects() {
  const projectsList = [
    // Max. 6 made with
    {
      title: 'WaterMark Generator',
      image: 'assets/projects/webwm.png',
      desc: 'Website WaterMark Tools',
      link: 'https://wmgenerator.vercel.app/',
      madeWith1: 'React',
      madeWith2: 'Redux',
      madeWith3: 'Bootstrap 5',
      madeWith4: 'JavaScript',
      madeWith5: 'SASS',
    },
    {
      title: 'E-tika Chat Dosen',
      image: 'assets/projects/webecd.png',
      desc: 'Website Chat Generator',
      link: 'https://etikachatdosen.vercel.app/',
      madeWith1: 'Bootstrap 5',
      madeWith2: 'JavaScript',
      madeWith3: 'SASS',
    },
    {
      title: 'Gangsther NFT',
      image: 'assets/projects/webnft.png',
      desc: 'Home Page Gangsther NFT',
      link: 'https://gangsther.vercel.app/',
      madeWith1: 'Bootstrap 5',
      madeWith2: 'JavaScript',
      madeWith3: 'SASS',
    },
    {
      title: 'Zall Panel',
      image: 'assets/projects/webadmin.png',
      desc: 'Dashboard Admin Template',
      link: 'none',
      madeWith1: 'HTML5',
      madeWith2: 'CSS3',
      madeWith3: 'JavaScript',
      madeWith4: 'SASS',
    },
    {
      title: 'Company Profile',
      image: 'assets/projects/webcompany.png',
      desc: 'Company Profile Template',
      link: 'none',
      madeWith1: 'Bootstrap 5',
      madeWith2: 'JavaScript',
      madeWith3: 'SASS',
    },
    {
      title: 'Inventaris Sekolah',
      image: 'assets/projects/webinven.png',
      desc: 'Website Inventaris Aset Sekolah',
      link: 'none',
      madeWith1: 'PHP Native',
      madeWith2: 'Bootstrap 4',
      madeWith3: 'JavaScript',
      madeWith4: 'MariaDB',
    },
    {
      title: 'Personal Web',
      image: 'assets/projects/webpersonal.png',
      desc: 'Personal Website Template',
      link: 'none',
      madeWith1: 'Bootstrap 4',
      madeWith2: 'CSS3',
      madeWith3: 'JavaScript',
    },
    {
      title: 'Pariwisata',
      image: 'assets/projects/webpariwisata.png',
      desc: 'Website Pariwisata Manado',
      link: 'none',
      madeWith1: 'HTML5',
      madeWith2: 'CSS3',
      madeWith3: 'Bootstrap 4',
    },
    {
      title: 'E-Voting',
      image: 'assets/projects/webevote.jpg',
      desc: 'Website E-Voting for OSIS',
      link: 'none',
      madeWith1: 'CodeIgniter 3',
      madeWith2: 'Bootstrap 4',
      madeWith3: 'JavaScript',
      madeWith4: 'MariaDB',
    },
    {
      title: 'Zall Blog',
      image: 'assets/projects/webblog.png',
      desc: 'Blog Template',
      link: 'none',
      madeWith1: 'Bootstrap 5',
      madeWith2: 'JavaScript',
      madeWith3: 'SASS',
    },
    {
      title: 'Aplikasi Sertifikasi',
      image: 'assets/projects/websertifikasi.png',
      desc: 'Website Sertifikasi (BNSP)',
      link: 'none',
      madeWith1: 'CodeIgniter 4',
      madeWith2: 'Bootstrap 4',
      madeWith3: 'JQuery',
      madeWith4: 'MariaDB',
    },
    {
      title: 'Sistem Informasi Jurusan',
      image: 'assets/projects/websisfo.png',
      desc: 'Website Sisfo Jurusan (SMKN 3 Manado)',
      link: 'none',
      madeWith1: 'CodeIgniter 3',
      madeWith2: 'Bootstrap 4',
      madeWith3: 'JavaScript',
      madeWith4: 'MariaDB',
    },
  ];

  return (
    <div className="animate__animated animate__fadeIn">
      <main className="content-page">
        <div className="container">
          <h2 className="title-page">Projects</h2>
          <div className="content projects">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {projectsList.map((projectItem, i) => {
                return (
                  <Project
                    key={i}
                    title={projectItem.title}
                    image={projectItem.image}
                    desc={projectItem.desc}
                    link={projectItem.link}
                    madeWith1={projectItem.madeWith1}
                    madeWith2={projectItem.madeWith2}
                    madeWith3={projectItem.madeWith3}
                    madeWith4={projectItem.madeWith4}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
