import { NavLink } from 'react-router-dom';

export default function Navbar() {
  // Menu Toggle
  function menuToggle() {
    document.getElementById('menuToggle').classList.toggle('open');
    document.getElementById('myNavbar').classList.toggle('openNav');
    document.body.classList.toggle('overflow-y');
  }

  // Menu Item
  function menuItem() {
    document.getElementById('menuToggle').classList.toggle('open');
    document.getElementById('myNavbar').classList.toggle('openNav');
    document.body.classList.remove('overflow-y');
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  // Navbar hide on scroll down
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById('myNavbar').style.bottom = '0';
      document.getElementById('myToggle').style.bottom = '0';
    } else {
      document.getElementById('myNavbar').style.bottom = '-78px';
      document.getElementById('myToggle').style.bottom = '-100px';
    }
    prevScrollpos = currentScrollPos;
  };

  return (
    <section
      className="navbar-wrapper d-flex fixed-bottom"
      onContextMenu={(e) => e.preventDefault()}
    >
      <div
        id="myNavbar"
        className="navbar-custom bg-white shadow d-flex align-items-center"
      >
        <NavLink to="/" onClick={() => menuItem()}>
          <div className="home">
            <img className="home-icon" src="assets/svg/home.svg" alt="..." />
            <div className="info shadow-sm">Home</div>
          </div>
        </NavLink>
        <NavLink to="/achievements" onClick={() => menuItem()}>
          <div className="achievements">
            <img
              className="achiev-icon"
              src="assets/svg/achievements.svg"
              alt="..."
            />
            <div className="info shadow-sm">Achievements</div>
          </div>
        </NavLink>
        <NavLink to="/projects" onClick={() => menuItem()}>
          <div className="projects">
            <img
              className="projects-icon"
              src="assets/svg/projects.svg"
              alt="..."
            />
            <div className="info shadow-sm">Projects</div>
          </div>
        </NavLink>
        <NavLink to="/skills" onClick={() => menuItem()}>
          <div className="skills">
            <img
              className="skills-icon"
              src="assets/svg/skills.svg"
              alt="..."
            />
            <div className="info shadow-sm">Skills</div>
          </div>
        </NavLink>
        <NavLink to="/contact" onClick={() => menuItem()}>
          <div className="contact">
            <img
              className="contact-icon"
              src="assets/svg/contact.svg"
              alt="..."
            />
            <div className="info shadow-sm">Contact</div>
          </div>
        </NavLink>
      </div>

      <div
        id="myToggle"
        className="menu-toggle-wrapper rounded-circle shadow-sm"
        onClick={() => menuToggle()}
      >
        <div id="menuToggle" className="menu-toggle">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </section>
  );
}
