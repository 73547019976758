export default function Skill(props) {
  return (
    <div className="col">
      <div className="card border-0 shadow h-100">
        <img
          src={props.image}
          className={'card-img-top ' + props.imgClass}
          alt="..."
        />
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">
            <span>{props.experience}</span> of experience
          </p>
        </div>
      </div>
    </div>
  );
}
