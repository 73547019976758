import Typed from '../components/MyTyped';

export default function Home() {
  // Parallax Animation (elements move when mouse moves)
  function valueX(e, v) {
    return (e.pageX * -1) / v;
  }

  function valueY(e, v) {
    return (e.pageY * -1) / v;
  }

  function parallax(e) {
    if (window.matchMedia('(min-width: 768px)').matches) {
      document.querySelector(
        '.design1'
      ).style.transform = `translate3d(${valueX(e, 55)}px, ${valueY(
        e,
        55
      )}px, 0)`;
      document.querySelector('.foto').style.transform = `translate3d(${valueX(
        e,
        45
      )}px, ${valueY(e, 45)}px, 0)`;
      document.querySelector(
        '.design2'
      ).style.transform = `translate3d(${valueX(e, 30)}px, ${valueY(
        e,
        30
      )}px, 0)`;
    }
  }

  return (
    <div className="animate__animated animate__fadeIn">
      <main className="content-home" onMouseMove={(e) => parallax(e)}>
        <div className="container">
          <div className="row">
            <div className="col-md left">
              <div className="title d-flex">
                <img
                  src="assets/svg/logo.svg"
                  alt="..."
                  onContextMenu={(e) => e.preventDefault()}
                />
                <h1>Rizall Kadamong</h1>
              </div>
              <div className="typing-wrapper">
                <Typed
                  strings={[
                    '^200Hi.^1800 Welcome to^200 my <span class="color">Website</span>!',
                    '^800I am an^300 <span class="color">IT Student</span>.',
                    '^500I love^400 <span class="color">Coding</span>.',
                    'I love^530 <span class="color">Designing</span>.',
                    '^295And playing^170 <span class="color">Musical Instruments</span>.',
                    '',
                  ]}
                />
              </div>
              <div className="sosmed">
                <h4>Find me!</h4>
                <div className="icon">
                  <a
                    href="https://www.facebook.com/rizall.kadamong.1/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/svg/facebook.svg" alt="..." />
                  </a>
                  <a
                    href="https://www.instagram.com/rzll.kadamong21/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/svg/instagram.svg" alt="..." />
                  </a>
                  <a
                    href="https://github.com/rizallk/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/svg/github.svg" alt="..." />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/rizall-kadamong/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/svg/linkedin.svg" alt="..." />
                  </a>
                  <a
                    href="https://soundcloud.com/rizallk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/svg/soundcloud.svg" alt="..." />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-auto col-md right"
              onContextMenu={(e) => e.preventDefault()}
            >
              <img
                className="design1"
                src="assets/svg/design1-home.svg"
                alt="..."
              />
              <img className="foto" src="assets/svg/foto.svg" alt="..." />
              <img
                className="design2"
                src="assets/svg/design2-home.svg"
                alt="..."
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
