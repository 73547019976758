import Modal from '../components/Modal';

export default function Achievements() {
  const dicodingList = [
    {
      link: 'https://www.dicoding.com/certificates/4EXG9Q979ZRL',
      text: 'Certificate of Completion in Belajar Jaringan Komputer untuk Pemula (2022)',
    },
    {
      link: 'https://www.dicoding.com/certificates/2VX3YO3JNPYQ',
      text: 'Certificate of Completion in Belajar Dasar-Dasar DevOps (2022)',
    },
    {
      link: 'https://www.dicoding.com/certificates/KEXLG0MQYZG2',
      text: 'Certificate of Completion in Memulai Pemrograman Dengan Python (2022)',
    },
    {
      link: 'https://www.dicoding.com/certificates/07Z6RV082PQR',
      text: 'Certificate of Completion in Belajar Dasar Visualisasi Data (2022)',
    },
    {
      link: 'https://www.dicoding.com/certificates/JLX1GD2JNZ72',
      text: 'Certificate of Completion in Belajar Membuat Aplikasi Back-End untuk Pemula dengan Google Cloud (2022)',
    },
    {
      link: 'https://www.dicoding.com/certificates/72ZDE8QMQPYW',
      text: 'Certificate of Completion in Belajar Membuat Aplikasi Flutter untuk Pemula (2021)',
    },
    {
      link: 'https://www.dicoding.com/certificates/81P21WLONZOY',
      text: 'Certificate of Completion in Memulai Pemrograman Dengan Dart (2021)',
    },
  ];

  const progateList = [
    {
      link: 'https://progate.com/path_certificate/bd287bd0qmvenj',
      text: 'Certificate of Completion in Path Pengembangan Web - NodeJS (2021)',
    },
    {
      link: 'https://progate.com/course_certificate/de7446aaqmacoj',
      text: 'Certificate of Completion in Kursus JavaScript (2021)',
    },
    {
      link: 'https://progate.com/course_certificate/7654bee7qmcwbp',
      text: 'Certificate of Completion in Kursus SQL (2021)',
    },
    {
      link: 'https://progate.com/course_certificate/633bb21fqmv1xw',
      text: 'Certificate of Completion in Kursus NodeJS (2021)',
    },
    {
      link: 'https://progate.com/course_certificate/ea72609cqorb15',
      text: 'Certificate of Completion in Kursus ReactJS (2021)',
    },
    {
      link: 'https://progate.com/course_certificate/ecd5bf29qorik5',
      text: 'Certificate of Completion in Kursus SASS (2021)',
    },
    {
      link: 'https://progate.com/course_certificate/c75992e0qp2o9g',
      text: 'Certificate of Completion in Kursus Command Line - UNIX (2021)',
    },
    {
      link: 'https://progate.com/course_certificate/feb66437qp4vu2',
      text: 'Certificate of Completion in Kursus Git (2021)',
    },
    {
      link: 'https://progate.com/course_certificate/1367d36fqp2anl',
      text: 'Certificate of Completion in Kursus Java (2021)',
    },
    {
      link: 'https://progate.com/course_certificate/604c2377qm1pmu',
      text: 'Certificate of Completion in Kursus HTML & CSS (2020)',
    },
  ];

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <main className="content-page">
          <div className="container">
            <h2 className="title-page">Achievements</h2>
            <div className="content achievements shadow">
              <div className="competitions">
                <h3>Competitions</h3>
                <ul>
                  <li>
                    2nd Place at Dies Natalis University of Sam Ratulangi in
                    Game Development Competition (2022)
                    <span className="_">*</span>
                  </li>
                  <li>
                    2nd Place at Vocational High School in Software Engineering
                    Quiz Competition (2018)<span className="_">*</span>
                  </li>
                  <li>
                    3rd Place at Vocational High School in Blog Competition
                    (2018)
                    <span className="_">*</span>
                  </li>
                </ul>
              </div>
              <div className="certificates">
                <h3>Certificates</h3>
                <ul className="general">
                  <li>
                    Certificate of Competence in Programming and Software
                    Development with qualification as Junior Web Developer (LSP
                    Computer / BNSP)<span className="_">*</span>
                  </li>
                  <li>
                    Alibaba Cloud Certification in Apsara Clouder - Cloud
                    Computing Using ECS to Construct a Dynamic Website (2021)
                    <span className="_">*</span>
                  </li>
                  <li>
                    Certificate of Training at Digital Talent Scholarship in Web
                    Developer organized by Kemkominfo (2020)
                    <span className="_">*</span>
                  </li>
                </ul>
                <ul className="online-course">
                  <li>
                    <span
                      className="modal-link"
                      data-bs-toggle="modal"
                      data-bs-target="#dicodingModal"
                    >
                      From Dicoding - Online Course
                    </span>
                  </li>
                  <li>
                    <span
                      className="modal-link"
                      data-bs-toggle="modal"
                      data-bs-target="#progateModal"
                    >
                      From Progate - Online Course
                    </span>
                  </li>
                </ul>
              </div>
              <p className="text">
                *Evidence of this achievement is not attached due to privacy
                reasons
              </p>
            </div>
          </div>
        </main>
      </div>

      <Modal
        title="dicoding"
        linkCourse="https://www.dicoding.com/"
        image="assets/images/dicoding-header-logo.png"
        list={dicodingList}
      />
      <Modal
        title="progate"
        linkCourse="https://progate.com/"
        image="assets/images/progate-logo.png"
        list={progateList}
      />
    </>
  );
}
