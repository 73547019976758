export default function CrD() {
  return (
    <div className="cr-d">
      <div className="d-flex flex-row-reverse">
        <img className="info" src="assets/svg/info.svg" alt="..." />
        <small className="text shadow-sm rounded">
          This Website is made with <span className="text-danger">❤</span> by
          Rizall Kadamong
        </small>
      </div>
    </div>
  );
}
