import Skill from '../components/Skill';

export default function Skills() {
  const skillsList = [
    {
      title: 'SASS',
      image: 'assets/skills/sass.svg',
      experience: '2 years',
    },
    {
      title: 'React',
      image: 'assets/skills/react.svg',
      experience: '2 years',
    },
    {
      title: 'MongoDB',
      image: 'assets/skills/mongodb.svg',
      imgClass: 'mongodb',
      experience: '2 years',
    },
    {
      title: 'ExpressJS',
      image: 'assets/skills/expressjs.svg',
      imgClass: 'express',
      experience: '2 years',
    },
    {
      title: 'NodeJS',
      image: 'assets/skills/nodejs.svg',
      experience: '2 years',
    },
    {
      title: 'JSON',
      image: 'assets/skills/json.svg',
      experience: '2 years',
    },
    {
      title: 'WordPress',
      image: 'assets/skills/wordpress.svg',
      experience: '4 years',
    },
    {
      title: 'JQuery',
      image: 'assets/skills/jquery.svg',
      experience: '4 years',
    },
    {
      title: 'CodeIgniter',
      image: 'assets/skills/codeigniter.svg',
      experience: '4 years',
    },
    {
      title: 'Bootstrap',
      image: 'assets/skills/bootstrap.svg',
      experience: '4+ years',
    },
    {
      title: 'MySQL / MariaDB',
      image: 'assets/skills/mysql.svg',
      experience: '4+ years',
    },
    {
      title: 'JavaScript',
      image: 'assets/skills/js.svg',
      experience: '4+ years',
    },
    {
      title: 'CSS',
      image: 'assets/skills/css.svg',
      experience: '4+ years',
    },
    {
      title: 'PHP',
      image: 'assets/skills/php.svg',
      experience: '4+ years',
    },
  ];

  const toolsList = [
    {
      title: 'Figma',
      image: 'assets/skills/figma.svg',
      experience: '2 years',
    },
    {
      title: 'VS Code',
      image: 'assets/skills/vscode.svg',
      experience: '3 years',
    },
    {
      title: 'Photoshop',
      image: 'assets/skills/photoshop.svg',
      experience: '4+ years',
    },
  ];

  return (
    <div className="animate__animated animate__fadeIn">
      <main className="content-page">
        <div className="container">
          <h2 className="title-page">Skills</h2>
          <div className="content skills">
            <div className="row row-cols-1 row-cols-md-4 g-4">
              {skillsList.map((skillItem, i) => {
                return (
                  <Skill
                    key={i}
                    title={skillItem.title}
                    image={skillItem.image}
                    experience={skillItem.experience}
                    imgClass={skillItem.imgClass}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {/* Tools */}
        <div className="container">
          <h2 className="title-page">Tools</h2>
          <div className="content skills">
            <div className="row row-cols-1 row-cols-md-4 g-4">
              {toolsList.map((toolItem, i) => {
                return (
                  <Skill
                    key={i}
                    title={toolItem.title}
                    image={toolItem.image}
                    experience={toolItem.experience}
                  />
                );
              })}
            </div>
          </div>

          {/* Last Updated Skills */}
          <p className="text-muted">Last updated 13-12-22</p>
        </div>
      </main>
    </div>
  );
}
