export default function Project(props) {
  return (
    <div className="col">
      <div className="card border-0 shadow h-100">
        {props.link === 'none' ? (
          <div className="card-img">
            <img src={props.image} className="card-img-top" alt={props.title} />
          </div>
        ) : (
          <a href={props.link}>
            <div className="card-img">
              <img
                src={props.image}
                className="card-img-top see"
                alt={props.title}
              />
              <p>See</p>
            </div>
          </a>
        )}
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.desc}</p>
          <span className="badge">{props.madeWith1}</span>
          <span className="badge">{props.madeWith2}</span>
          <span className="badge">{props.madeWith3}</span>
          <span className="badge">{props.madeWith4}</span>
          <span className="badge">{props.madeWith5}</span>
          <span className="badge">{props.madeWith6}</span>
        </div>
      </div>
    </div>
  );
}
